import { t } from 'i18next'
import { Link, useLocation } from 'react-router-dom'
//import ChooseLang from '../ChooseLang/ChooseLang'
import './TopPage.scss'
import { useState } from 'react'

export default function TopPage() {
  const location = useLocation()
  const [isMenuExpanded, setMenuExpanded] = useState(false)

  const toggleMenu = () => {
    setMenuExpanded(!isMenuExpanded)
  }

  return (
    <section className="section-top top">
      <div
        className={`section_container ${
          location.pathname !== '/' && 'section_container-job'
        }`}
      >
        <img src="/imgs/element_up.svg" alt="round" className="top_imgtop" aria-hidden="true" />
        <div className="topHeader">
          <div className="topHeader_back-wrapper">
            {location.pathname !== '/' && !location.pathname.startsWith('/section') && (
              <Link
                className="topHeader_back topHeader_back-desktop c-pink"
                to="/"
              >
                {`< ${t('back')}`}
              </Link>
            )}
          </div>
          {location.pathname !== '/' ? (
            <Link to="/">
              <img
                className="top_imgLogo logo topHeader_img"
                alt="logo"
                src="/imgs/levelup_logo_horizontal.svg"
              />
            </Link>
          ) : (
            <img
              className="top_imgLogo logo topHeader_img"
              alt="logo"
              src="/imgs/levelup_logo_horizontal.svg"
            />
          )}
          {/* <ChooseLang /> */}
          {(location.pathname === '/' || location.pathname.startsWith('/section')) && (
            <nav role="navigation" aria-label="Main menu" className='topHeader_anchorNav'>
              <button aria-expanded={isMenuExpanded} onClick={toggleMenu}>
                  <img src="/imgs/icon_hamburger.svg" alt="Menu" />
              </button>
              <ul>
                <li><a onClick={toggleMenu} href='#section-steps'>{t('header.links.0.title')}</a></li>
                <li><a onClick={toggleMenu} href='#section-who'>{t('header.links.1.title')}</a></li>
                <li><a onClick={toggleMenu} href='#section-working'>{t('header.links.2.title')}</a></li>
                <li><a onClick={toggleMenu} href='#section-tries'>{t('header.links.3.title')}</a></li>
                <li><a className='btn btn-primary' onClick={toggleMenu} href='/login'>{t('header.links.4.title')}</a></li>
              </ul>
            </nav>
          )}
          
        </div>

        {location.pathname !== '/' && !location.pathname.startsWith('/section') && (
          <Link className="topHeader_back topHeader_back-mobile c-pink" to="/">
            {`< ${t('back')}`}
          </Link>
        )}
      </div>
    </section>
  )
}
