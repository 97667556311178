import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './Footer.scss'

export default function Footer() {
  const { t } = useTranslation()

  const links: any = t('footer.links', { returnObjects: true })


  const URL_MAILTO = process.env.REACT_APP_URL_MAILTO

  return (
    <footer className="footer">
      <div className="footer_container">
        <div className=" row row-linksFooterStart footer_start linksFooter">
          <Trans i18nKey="key">{t('footer.credits')}</Trans>
        </div>
        <div className="footer_end">
          <div className="row row-linksFooterEnd linksFooter">
            {links.map((link: {[key: string]: any} ) => (
              <div key={link.title} className="row_col">
                <Link className="linksFooter_link linksFooter_link-cookie" to={link.url}>
                  {link.title}
                </Link>
              </div>
            ))}
            <div className="row_col">
              <a
                className="linksFooter_link linksFooter_link-mail"
                href={URL_MAILTO}
              >
                <Trans i18nKey="key">{t('footer.contact')}</Trans>
                &nbsp;
                <MailOutlineRoundedIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
