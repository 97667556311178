import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'
import Footer from '../Components/Footer/Footer'
import Parteners from '../Components/Parteners/Parteners'
import TopPage from '../Components/TopPage/TopPage'
import Home from '../Pages/Home'
import { useLocation } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { AnimatePresence } from 'framer-motion'

const JobPage = React.lazy(() => import('../Pages/jobPage'))
const Confidencialite = React.lazy(() => import('../Pages/Confidencialite'))
const CookiesPage = React.lazy(() => import('../Pages/CookiesPage'))
const PartenersPage = React.lazy(() => import('../Pages/PartenersPage'))

//import Profil from '../Pages/Profil';
// import NoMatch from '../Pages/404';
// import Layout from '../Pages/Layout';

export default function Routing() {
  const { t } = useTranslation()
  const location = useLocation()

  const linksFooter: Array<Object> = t('footer.links', { returnObjects: true })

  return (
    <>
      <TopPage />
      {/* <Layout /> */}
      <Suspense fallback={<div style={{height: '100%', width: '100%'}}><CircularProgress /></div>}>
        {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
        <AnimatePresence mode='wait' >
          <Routes location={location} key={location.key}>
            <Route path="/" element={<Home />} />
            {linksFooter.map((link: { [key: string]: any }) => (
              <Route
                key={link.title}
                path={link.url}
                element={
                  link.url === '/cookies' ? (
                    <CookiesPage />
                  ) : (
                    <Confidencialite />
                  )
                }
              />
            ))}

            <Route path="*" element={<Home />} />
            <Route path="jobs/:name" element={<JobPage />} />
            <Route path="partners" element={<PartenersPage />} />
            {/* </Route> */}
          </Routes>
        </AnimatePresence>
      </Suspense>
      {location.pathname !== '/partners' && <Parteners />}
      <Footer />
    </>
  )
}
