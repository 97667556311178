import ReactGA from "react-ga4";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import "./Try.scss";
import { Cookies } from "react-cookie-consent";
import { Trans, useTranslation } from "react-i18next";
export default function Try() {
  const { t } = useTranslation();

  const Tries: any = t("try.tries", { returnObjects: true });
  const Tries2: any = t("try2.tries", { returnObjects: true });

  const GoToCourse = (url: string) => {
    const cookieValue = Cookies.get("CookieConsent");
    console.info("cookieValue", cookieValue, typeof(cookieValue))
    if (cookieValue === 'true') {

         console.log('test', ReactGA)

    ReactGA.event({
        category: "courses actions",
        action: "CTA action",
        label: url.includes("cybersecurity") ? "Cybersecurity CTA" : 'IoT CTA',  // optional
        value: 1, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image

    });
    }
    window.open(url, "_blank");
  };

  return (
    <section id="section-tries" className="section section-tries">
      <div className="section_container">
        <h2 className="section_titleBaseline c-blue display-TitleSection">
          <Trans i18nKey="key">{t("try.title")}</Trans>
        </h2>

        <p className="section_baseline display-txtBaseline">
          <Trans i18nKey="key">{t("try.baseline")}</Trans>
        </p>
        <div className="tries">
          {Tries.map((Try: {[key: string]: any} ) => (
            <article key={Try.title} className="card">
              <div>
                <p className="card_title display-txtBold c-blue">{Try.title}</p>
                <p
                  className="card_txt c-middleGrey display-Txtlittle"
                  dangerouslySetInnerHTML={{ __html: Try.txt }}
                />
              </div>
              <div>
                <button
                  rel="noreferrer"
                  onClick={() => GoToCourse(Try.link)}
                  className={"card_btn btn btn-secondary btn-small header_txt-btn"}
                >
                  {t("try.btn")}
                  
                  <ArrowForwardRoundedIcon />{" "}
                </button>
              </div>
            </article>
          ))}
        </div>
        <p className="tries_mentions display-mention c-middleGrey">
          <Trans i18nKey="key">{t("try.credits")}</Trans>
        </p>
        <p className="section_baseline display-txtBaseline">
          <Trans i18nKey="key">{t("try2.baseline")}</Trans>
        </p>
        <div className="tries tries--2">
          {Tries2.map((Try2: {[key: string]: any} ) => (
            <article key={Try2.title} className="card">
              <div>
                <p className="card_title display-txtBold c-blue">{Try2.title}</p>
                <p
                  className="card_txt c-middleGrey display-Txtlittle"
                  dangerouslySetInnerHTML={{ __html: Try2.txt }}
                />
              </div>
              <div>
                <button
                  rel="noreferrer"
                  onClick={() => GoToCourse(Try2.link)}
                  className={"card_btn btn btn-secondary btn-small header_txt-btn"}
                >
                  {t("try.btn")}
                  
                  <ArrowForwardRoundedIcon />{" "}
                </button>
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
}
