// import ReactGA from "react-ga4";
import './Who.scss'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

// import required modules
import { Pagination } from 'swiper/modules'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBaseContext } from '../../Contexts/base-context'

export default function Who() {
  const { t } = useTranslation()

  const [Videos, setVideos] = useState([])
  const { setIsPopUpVideo, isPopUpVideo, setVideoCurrent } = useBaseContext()

  useEffect(() => {
    setVideos(t('temoignages.videos', { returnObjects: true }))
  }, [setIsPopUpVideo, t])

  return (
    <section id="section-who" className="section section-who">
      <div className="section_container">
        <h2 className="section_titleBottom section_titleBottom-who  c-pink display-TitleSection">
          {t('who.title')}
        </h2>

 
        <div className="testimonals">
          <div className="testimonals_header">
            <img
              src="/imgs/who/testimonial_icon.png"
              alt="testimonial_icon"
              className="testimonals_img"
            />
            <span className="testimonals_title">{t('temoignages.title')}</span>
          </div>

          <div className="testimonalsList testimonalsList-desktop">
            {Videos?.map((video: any, i: number) => {
              return (
                <div key={video.name} className="testimonalsList_item">
                  <p className="testimonalsList_title">{video.name}</p>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      setIsPopUpVideo(true)
                      setVideoCurrent(video)
                    }}
                    className="testimonalsList_video linkVideo"
                  >
                    <img
                      className="linkVideo_img"
                      src={video.poster}
                      alt={video.name}
                    />
                    <img
                      className="linkVideo_icon"
                      src="/imgs/icon/play_icon.png"
                      alt="icon player"
                    />
                  </button>
                  <div className="testimonalsList_caption">
                    <img
                      className="testimonalsList_caption-before"
                      src="/imgs/icon/quote_icon.png"
                      alt="quote_icon"
                    />
                    <span className="testimonalsList_caption-txt">
                      {video.details}
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="swiper_container">
          <div className="swiper_container-inner">
            <Swiper
              slidesPerView={2}
              spaceBetween={31}
              pagination={{
                clickable: true,
              }}
              loop={false}
              centeredSlides={true}
              modules={[Pagination]}
              className="mySwiper testimonalsList-mobile"
            >
              {Videos.map((u: {[key: string]: any} , i: number) => {
                return (
                  <SwiperSlide key={u.name + 'slider'}>
                    <div className="testimonalsList_item">
                      <p className="testimonalsList_title">{u.name}</p>
                      <button
                        onClick={(e) => {
                          e.preventDefault()
                          console.log(isPopUpVideo, 'isPopUpVideo')
                          setIsPopUpVideo(true)
                          setVideoCurrent(u)
                        }}
                        className="testimonalsList_video linkVideo"
                      >
                        <img
                          className="linkVideo_img"
                          src={u.poster}
                          alt={u.name}
                        />
                        <img
                          className="linkVideo_icon"
                          src="/imgs/icon/play_icon.png"
                          alt="icon player"
                        />
                      </button>
                      <div className="testimonalsList_caption">
                        <img
                          className="testimonalsList_caption-before"
                          src="/imgs/icon/quote_icon.png"
                          alt="quote_icon"
                        />
                        {u.details}
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>

        <div className="infos row">
          <div className="infos_item row_col">
            <div className="info_txt infoTxt">
              <div className="infoTxt_header">
                <img
                  src="/imgs/who/rocket_icon.png"
                  alt="Présentation_icon"
                  className="infoTxt_img"
                />
                <span className="infoTxt_title">
                  {t('who.presentation.title')}
                </span>
              </div>
              <p className="infoTxt_subtitle">{t('who.presentation.subtitle')}</p>

              <p className="infoTxt_txt">{t('who.presentation.txt')}</p>
            </div>
            <div className="infoTxt_video">
              <button
                className="linkVideo"
                aria-labelledby={t('who.presentation.title')}
                onClick={(e) => {
                  //  console.log(isPopUpVideo, 'isPopUpVideo')
                  e.preventDefault()
                  setIsPopUpVideo(true)
                  setVideoCurrent(
                    t('who.presentation.video', { returnObjects: true })
                  )
                }}
              >
                <img
                  className="linkVideo_img"
                  src={t('who.presentation.video.poster')}
                  alt="poster of the video"
                />
                <img
                  className="linkVideo_icon"
                  src="/imgs/icon/play_icon.png"
                  alt="icon of the video"
                />
              </button>
            </div>
          </div>
          <div className="infos_item row_col">
            <div className="info_txt infoTxt">
              <div className="infoTxt_header">
                <img
                  src="/imgs/who/portrait_icon.png"
                  alt="portrait_icon"
                  className="infoTxt_img"
                />
                <span className="infoTxt_title">{t('who.portrait.title')}</span>
              </div>

              <p className="infoTxt_subtitle">{t('who.portrait.subtitle')}</p>

              <p className="infoTxt_txt">{t('who.portrait.txt')}</p>
            </div>
            <div className="infoTxt_video">
              <button
                className="linkVideo"
                aria-labelledby={t('who.portrait.title')}
                onClick={(e) => {
                  e.preventDefault()
                  setIsPopUpVideo(true)
                  setVideoCurrent(
                    t('who.portrait.video', { returnObjects: true })
                  )
                }}
              >
                <img
                  className="linkVideo_img"
                  src={t('who.portrait.video.poster')}
                  alt={t('who.portrait.title')}
                />
                <img
                  className="linkVideo_icon"
                  src="/imgs/icon/play_icon.png"
                  alt="icon player"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
