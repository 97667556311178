import { motion } from 'framer-motion'
import Header from '../Components/Header/Header'
// import Jobs from '../Components/Jobs/Jobs'
import PopUpVideo from '../Components/PopUpVideo/PopUpVideo'

// import Progress from '../Components/Progress/Progress'
import Steps from '../Components/Steps/Steps'
import Try from '../Components/Try/Try'
import Who from '../Components/Who/Who'
import Working from '../Components/Working/Working'

export default function Home() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: .25, duration: .25 }}
    >
      <Header />
      {/* <Progress /> */}
      <Steps />
      <Who />

      <Working/>
      <Try />

      
      {/* <Jobs /> */}
      <PopUpVideo />
    </motion.div>
  )
}
