import { useContext, useState, createContext } from "react"

 
const BaseContext = createContext({
    isBanner: true,
    setIsBanner: (value: boolean) => {},

    isPopUpVideo: false,
    setIsPopUpVideo: (value: boolean) => {},

    videoCurrent:  {
      name: 'Mohamed-Amine',
      path: '/videos/MOHAMED-AMINE/video.mp4',
      srt: {
        en: '/videos/MOHAMED-AMINE/srt/en.vtt',
        fr: '/videos/MOHAMED-AMINE/srt/fr.vtt',
      },
      details: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh.`,
    },
    setVideoCurrent: (value: Object) => {},
  })

export function useBaseContext (){
    return useContext(BaseContext)
}

export function BaseContextProvider ({children}: any){
    const [isBanner, setIsBanner] = useState(true)
    const [isPopUpVideo, setIsPopUpVideo] = useState(false)
    const [videoCurrent, setVideoCurrent] = useState({
      name: 'Mohamed-Amine',
      path: '/videos/MOHAMED-AMINE/video.mp4',
      srt: {
        en: '/videos/MOHAMED-AMINE/srt/en.vtt',
        fr: '/videos/MOHAMED-AMINE/srt/fr.vtt',
      },
      details: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed posuere nibh.`,
    })

    return <BaseContext.Provider value={{
        isBanner: isBanner || true,
        setIsBanner: (value: boolean) => setIsBanner(value),
    
        isPopUpVideo: isPopUpVideo || false,
        setIsPopUpVideo: (value: boolean) => setIsPopUpVideo(value),
    
        videoCurrent: videoCurrent || {},
        setVideoCurrent: (value: any) => setVideoCurrent(value),
      }}>
        {children}
    </BaseContext.Provider>
}