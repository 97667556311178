import React, { useState, useEffect } from 'react'
import './ScrollButton.scss'



const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false)

  const scrollValue = 400;

  useEffect(() => {
    const checkScrollHeight = () => {
      if (!showButton && window.pageYOffset > scrollValue) {
        setShowButton(true)
      } else if (showButton && window.pageYOffset <= scrollValue) {
        setShowButton(false)
      }
    }

    window.addEventListener('scroll', checkScrollHeight)
    return () => {
      window.removeEventListener('scroll', checkScrollHeight)
    }
  }, [showButton])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <span className={`ButtonContainer ${showButton ? 'active' : ''}`} onClick={scrollToTop}>
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        stroke="#ffffff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12 19V5M5 12l7-7 7 7" />
      </svg>
    </span>
  )
}

export default BackToTopButton
