import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector';

import { EN } from './Langs/EN'
import { FR } from './Langs/FR'

i18n
.use(LanguageDetector) // <= new
.use(initReactI18next)
.init({
  fallbackLng: 'en',
  lng: 'fr',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
    transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span', 'p'],
    transWrapTextNodes: '',
  },
  resources: {
    fr: FR,
    en: EN,
  },
})

export default i18n
