// import ReactGA from "react-ga4";

import { Trans, useTranslation } from 'react-i18next';

import './Working.scss'
// import { Cookies } from "react-cookie-consent";

export default function Working() {

  
  const { t } = useTranslation();

  const CoursesV2: any = t("coursesv2.courses", { returnObjects: true });

  return (
    <section id="section-working" className="section section-working">
      <div className="section_container">
        <h2 className="section_titleBottom section_titleBottom-working  c-pink display-TitleSection">
        <Trans i18nKey="key">{t("coursesv2.title")}</Trans>
        </h2>

        <div className="workingCards">
        {CoursesV2.map((CourseV2: {[key: string]: any}, index:any ) => (
        <article key={index} className='workingCard'>
        <div className='workingCard_image'>
            <span className='workingCard_number'>
            <Trans i18nKey="key">{t(CourseV2.number)}</Trans>
            </span>
            <div className='workingCard_containerImage'>
              <img className='workingCard_img' src={CourseV2.image} alt={CourseV2.alt} />
            </div>
          </div>

          <div className='workingCard_description'>
          <p
                className="workingCard_txt"
                dangerouslySetInnerHTML={{ __html: CourseV2.text }}
              />
          
            
            <span className='workingCard_separator'></span>
          </div>
        </article>
        ))}

        </div>

      </div>
    </section>
  )
}
