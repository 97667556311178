import * as React from 'react'
import { styled, css } from '@mui/system'
import { Modal as BaseModal } from '@mui/base/Modal'
import Fade from '@mui/material/Fade'
import ReactPlayer from 'react-player'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useBaseContext } from '../../Contexts/base-context'
import i18n from '../../i18n'
export default function PopUpVideo() {
  const { setIsPopUpVideo, isPopUpVideo, videoCurrent } = useBaseContext()
  // const { setIsPopUpVideo, isPopUpVideo, videoCurrent } = useContext(MyContext)
  // const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setIsPopUpVideo(false)
    // setIsPopUpVideo({})
  }


  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isPopUpVideo}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={isPopUpVideo} {...(isPopUpVideo ? { timeout: 1000 } : {})}>
          <ModalContent sx={style}>
            <h2 id="transition-modal-title" className="modal-title">
              {videoCurrent?.name}
            </h2>
            <div className="player-wrapper">
              <ReactPlayer
                controls
                url={videoCurrent?.path}
                className="react-player"
                width="100%"
                height="100%"
               
                config={{
                  file: {
                    tracks: [
                      {
                        kind: 'subtitles',
                        src: videoCurrent?.srt.en,
                        srcLang: 'en',
                        label: 'en',
                        default: getCurrentLng() !== 'fr',
                      },
                      {
                        kind: 'subtitles',
                        src: videoCurrent?.srt.fr,
                        srcLang: 'fr',
                        label: 'fr',
                        default: getCurrentLng() === 'fr',
                      },
                    ],
                  },
                }}

                // style={{maxHeight: '60vh', width: 'auto'}}
              />
            </div>
            <ButtonBack onClick={handleClose}>
              <CloseRoundedIcon sx={buttonIcon} />
            </ButtonBack>
          </ModalContent>
        </Fade>
      </Modal>
    </div>
  )
}

const Backdrop = React.forwardRef<HTMLDivElement, { open?: boolean }>(
  (props, ref) => {
    const { open, ...other } = props
    return (
      <Fade in={open}>
        <div ref={ref} {...other} />
      </Fade>
    )
  }
)

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-tap-highlight-color: transparent;
`
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
}

const buttonIcon = {
  width: '48px',
  height: '48px',
  color: 'white',
}

const ButtonBack = styled('button')`
  background: rgba(30, 30, 34, 1);
  margin-top: 30px;
  margin-bottom: 10px;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1)
  transition:.25s all;
  cursor: pointer;
  &:hover {transform: scale(1.05)}
  &:active {transform: scale(1.1)}

  @media(max-width:768px){
   position: absolute;
   top: 8px;
   right: 8px;  
   width: 34px;
   height: 34px;
   margin: 0;
  }
  & svg{
    @media(max-width:768px){
    width: 24px;
    height: 24px;
  }
  }
`

const ModalContent = styled('div')(
  () => css`
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: white;
  }
    & .modal-title {
      width: 100%;
      max-width: 276px;
      margin-bottom: 11px;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: 0em;
      text-align: right;
      @media(max-width:768px){
        display:none;
      }
    }
  `
)
